import { library } from '@fortawesome/fontawesome-svg-core';

// Brands
import { faGooglePlay as fabGooglePlay } from '@fortawesome/free-brands-svg-icons/faGooglePlay';
import { faApple as fabApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';

// Regular
import { faEllipsisHAlt as farEllipsisHAlt } from '@fortawesome/pro-regular-svg-icons/faEllipsisHAlt';

const exports = {
  fabApple,
  fabGooglePlay,
  farEllipsisHAlt,
  fabFacebook,
  fabInstagram,
  fabLinkedin,
  fabTwitter,
};

export default library.add(...Object.values(exports));
