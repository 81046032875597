import React, { Component } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import BounceLoader from 'react-spinners/BounceLoader';

import playStoreBadge from 'assets/playstore.png';
import appStoreBadge from 'assets/appstore.png';
import webBadge from 'assets/web.png';

export default class ClientCard extends Component {
  constructor(props) {
    super(props);

    this.mobile = React.createRef();
    this.overlayWrapper = React.createRef();

    this.state = {
      mobileChildren: null,
      image: null, // `https://via.placeholder.com/242x160?text=${props.name}`,
      iconImage: null,
      opacity: 0,
      loading: true,
    };

    this.checkViewport = this.checkViewport.bind(this);
  }

  componentDidMount() {
    const { mobileChildren } = this.state;
    const { current: mobile } = this.mobile;

    if (mobileChildren === null && mobile) {
      const { length } = Array.from(mobile.querySelectorAll('img'));
      this.setState({ mobileChildren: `c${length}` });
    }

    this.checkViewport();
    window.addEventListener('scroll', this.checkViewport);
  }

  checkViewport() {
    const { name, bg, icon } = this.props;
    const { image, opacity } = this.state;
    const { current: overlayWrapper } = this.overlayWrapper;

    const rect = overlayWrapper && overlayWrapper.getBoundingClientRect();
    const inViewport = rect.top - 100 <= window.innerHeight;

    this.to = null;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('apps')) {
      const img = new Image();
      img.onload = () => {
        this.setState({ image: bg }, () => {
          this.to = setTimeout(() => {
            this.setState({ opacity: 1 });
          }, 500);
        });
      };
      img.src = bg;
      this.setState({ iconImage: icon });
    } else if (bg && inViewport) {
      const img = new Image();
      img.onload = () => {
        this.setState({ image: bg }, () => {
          this.to = setTimeout(() => {
            this.setState({ opacity: 1 });
          }, 500);
        });
      };
      img.src = bg;
      this.setState({ iconImage: icon });
    } else if (image === null && inViewport) {
      const img = new Image();
      img.onload = () => {
        this.setState(
          { image: `https://via.placeholder.com/242x160?text=${name}` },
          () => {
            this.to = setTimeout(() => {
              this.setState({ opacity: 1 });
            }, 500);
          },
        );
      };
      img.src = `https://via.placeholder.com/242x160?text=${name}`;
    } else if (image !== null && opacity === 1) {
      setTimeout(() => {
        this.setState({ loading: false });
        window.removeEventListener('scroll', this.checkViewport);
      }, 500);
    }
  }

  render() {
    const {
      name,
      webLink,
      appStore,
      playStore,
      comingSoon,
      featuredCourse,
    } = this.props;
    const { mobileChildren, image, iconImage, opacity, loading } = this.state;

    const loaderStyle = `
      opacity: ${opacity ? 0 : 1};
      transition: opacity 500ms ease-in-out;
    `;

    return (
      <div className={clsx('wrapper__card', { unloaded: image === null })}>
        <div
          className={clsx('wrapper__overlay', { unloaded: image === null })}
          ref={this.overlayWrapper}
        >
          <img
            src={image}
            className='image'
            alt={`${name}_card`}
            style={{ opacity }}
          />
          <img
            className='icon'
            src={iconImage}
            alt={`${name}_card`}
            style={{ opacity }}
          />
          {loading && (
            <div className='wrapper__loader'>
              <BounceLoader
                className='loader'
                css={loaderStyle}
                loading={loading}
              />
            </div>
          )}
          <div className='name'>{name}</div>
          {featuredCourse ? (
            <div className='featured__text'>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='wrapper__mobile' ref={this.mobile}>
          {appStore ? (
            <a href={appStore} target='_blank' rel='noopener noreferrer'>
              <img src={appStoreBadge} alt='App Store app link' />
            </a>
          ) : (
            <div className={clsx(mobileChildren)} />
          )}
          {playStore ? (
            <a href={playStore} target='_blank' rel='noopener noreferrer'>
              <img src={playStoreBadge} alt='Play Store app link' />
            </a>
          ) : (
            ''
          )}
          {webLink ? (
            <a href={webLink} target='_blank' rel='noopener noreferrer'>
              <img src={webBadge} alt='Web app link' />
            </a>
          ) : (
            ''
          )}
          {comingSoon === 1 ? (
            <span className='coming__soon'>
              <hr />
              Coming Soon!
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

ClientCard.propTypes = {
  name: PropTypes.string,
  webLink: PropTypes.string,
  appStore: PropTypes.string,
  playStore: PropTypes.string,
  comingSoon: PropTypes.string,
  bg: PropTypes.string,
  icon: PropTypes.string,
  featuredCourse: PropTypes.bool,
};

ClientCard.defaultProps = {
  name: 'TravPRO Mobile',
  webLink: 'https://travpromobile.com',
  appStore: null,
  playStore: null,
  comingSoon: null,
  bg: null,
  icon: null,
  featuredCourse: false,
};
