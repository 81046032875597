import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

const Header = ({ children, className }) => (
  <header className={clsx('header__clients', className)}>
    <h1>{children}</h1>
    <hr />
  </header>
);

export default Header;

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: null,
};
