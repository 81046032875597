export default {
  lang: 'English',
  intro: {
    welcome: 'The largest collection of B2B Mobile Learning and Sales Enablement programs in the industry.',
    points: {
      1: 'Are you looking for a learning experience that truly inspires you and prepares you to sell a destination or brand? What if you could take the training anywhere, even offline with mobile apps? And what if you could harness all that great content and then activate it with marketing and sales support you can tap into <span style="font-style: italic; font-weight: bold;">whenever</span> and <span style="font-style: italic; font-weight: bold;">wherever</span> you needed it?',
      2: 'You can do all this and more with <span style="font-weight: bold;">TravPRO</span>. We provide the best training experience in the world and the only platform that helps bridge the gap from training to selling.',
      3: 'The mobile <span style="font-weight: bold;">Sales Companion™</span> attached to each of these training programs ensures you always have the best content and support at your fingertips so you can start more conversations and close more bookings.  That’s right, you will have all of the necessary information and tools in the palm of your hands when you are talking to potential clients!',
      4: 'Visit any of the below programs by clicking on the web link or downloading the app. Start at <span style="font-weight: bold;">TRAIN</span>, revisit the information in <span style="font-weight: bold;">RETAIN</span>, share the pre-populated marketing materials in <span style="font-weight: bold;">PROMOTE</span>, and use the <span style="font-weight: bold;">SELL</span> area to go deeper, add value and close sales.',
    },
  },
  socials: {
    follow: 'Follow us on:',
  },
};
