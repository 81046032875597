/* eslint-disable react/no-danger */
import React, { Component } from 'react';

import i18n from 'i18n-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ClientCard from 'components/ClientCard';
import Header from 'components/Header';

import travproLogo from 'assets/logo.png';
import source from 'assets/source.png';

import './index.scss';
import 'assets/styles.scss';
import 'config/FontAwesome';
import 'config/i18n';

const socials = {
  facebook: 'https://www.facebook.com/travpromobile/',
  instagram: 'https://www.instagram.com/travpromobile/',
  twitter: 'https://twitter.com/TravProMobile',
  linkedin: 'https://www.linkedin.com/company/travpro-mobile/',
};

// TODO full width button if web only
// TODO icon design
export default class App extends Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();

    this.state = {
      apps: [],
      onlyApps: false,
      totalApps: 0,
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('apps')) {
      this.setState({
        onlyApps: true,
      });
    }

    this.fetchApps();
  }

  async fetchApps() {
    const response = await fetch(
      'https://cms.travpromobile.com/api/app/projects',
    );
    const apps = await response.json();

    const appState = Object.keys(apps).map(key => {
      const cleanedJson = apps[key]
        .map(app => {
          const {
            app_id: appId,
            app_store: appStore,
            web_link: webLink,
            play_store: playStore,
            coming_soon: comingSoon,
            ...rest
          } = app;

          this.setState({
            totalApps: this.state.totalApps + 1,
          });

          return {
            appId,
            appStore,
            webLink,
            playStore,
            comingSoon,
            ...rest,
          };
        })
        .sort((a, b) => a.category > b.category);

      if (key === '00_recently_launched') {
        return null;
      }

      if (key === '000_coming_soon') {
        return null;
      }

      return (
        <React.Fragment>
          <Header>
            {key.replace(/[_]/g, ' ').replace(/^([0-9]*\s)?/g, '')}
          </Header>
          {cleanedJson.map(({ appId, ...rest }) => (
            <ClientCard key={appId} {...rest} />
          ))}
        </React.Fragment>
      );
    });

    const appFeaturedState = Object.keys(apps).map(key => {
      const cleanedJson = apps[key]
        .map(app => {
          const {
            app_id: appId,
            app_store: appStore,
            web_link: webLink,
            play_store: playStore,
            coming_soon: comingSoon,
            ...rest
          } = app;

          return {
            appId,
            appStore,
            webLink,
            playStore,
            comingSoon,
            ...rest,
          };
        })
        .sort((a, b) => a.category > b.category);

      return (
        <React.Fragment>
          {cleanedJson.map(({ appId, ...rest }) => (
            <React.Fragment>
              {rest.featured !== 0 && (
                <ClientCard key={appId} featuredCourse={true} {...rest} />
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    });

    const appComingState = Object.keys(apps).map(key => {
      const cleanedJson = apps[key]
        .map(app => {
          const {
            app_id: appId,
            app_store: appStore,
            web_link: webLink,
            play_store: playStore,
            coming_soon: comingSoon,
            ...rest
          } = app;

          return {
            appId,
            appStore,
            webLink,
            playStore,
            comingSoon,
            ...rest,
          };
        })
        .sort((a, b) => a.category > b.category);

      return (
        <div className='comingSoon'>
          {cleanedJson.map(({ appId, comingSoon, ...rest }) => (
            <React.Fragment>
              {comingSoon !== 0 && (
                <img src={rest.icon} alt={rest.name} key={appId} />
              )}
            </React.Fragment>
          ))}
        </div>
      );
    });

    let height = 83;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 560) {
      height = 385;
    } else if (width < 845) {
      height = 180;
    } else if (width < 1135) {
      height = 120;
    }

    // eslint-disable-next-line react/destructuring-assignment
    window.parent.postMessage(this.state.totalApps * height, '*');
    this.setState({
      apps: appState,
      featuredApps: appFeaturedState,
      comingSoon: appComingState,
    });
  }

  // TODO remove hover stuff
  render() {
    const { apps, featuredApps, comingSoon, onlyApps } = this.state;
    return (
      <React.Fragment>
        <div className='container'>
          <div className='top-header'>
            <a href='https://travpromobile.com'>
              <img src={travproLogo} alt='TravPRO Mobile Logo' />
            </a>
          </div>
          <div className='header'>
            <img className='source' src={source} alt='TravPRO Mobile Logo' />
            <br />
            <span
              className='head'
              dangerouslySetInnerHTML={{
                __html: i18n.translate('intro.welcome'),
              }}
            />
            <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.translate('intro.points.1'),
              }}
            />
            <br />
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.translate('intro.points.2'),
              }}
            />
            <br />
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.translate('intro.points.3'),
              }}
            />
            <br />
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.translate('intro.points.4'),
              }}
            />
            <h1 className='coming-header'>Launching Soon</h1>
            <hr />
            <div className='coming'>{comingSoon}</div>

            <h1 className='featured-header'>Recently Launched</h1>
            <hr />
            <div className='featured'>{featuredApps}</div>
            {/* <a className="aar" href="https://quarkexpeditionspolarpro.com/login" rel="noopener noreferrer" target="_blank"><img src="https://front.travpromobile.com/source/QuarkPolarPro.jpg" alt="Quark Polar PRO" /></a> */}
          </div>
          <div className='wrapper__clients'>
            <div className='container__clients'>{apps}</div>
          </div>
        </div>
        <div className='socials'>
          <div className='title'>{i18n.translate('socials.follow')}</div>
          {Object.keys(socials).map(key => (
            <a href={socials[key]} target='_blank' rel='noopener noreferrer'>
              <FontAwesomeIcon icon={['fab', key]} />
            </a>
          ))}
        </div>
        <Header className='socialHeader'>Instagram</Header>
        <div
          className='embedsocial-hashtag'
          data-ref='1ff494511633876e3c5a1ae793fdb424da771ec8'
        />
        {onlyApps === true && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .top-header { display: none; } .header { display: none; } .socials { display: none; } .embedsocial-hashtag { display: none; } .socialHeader { display: none; } .widgetBoxArrow { display: none !important; } #root:after { display: none; } .wrapper__clients .container__clients .header__clients { display: none; }
          `,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
